<!-- @format -->

<template>
	<div>
		<div class="margin-top-20">
			<CustomerDetailsForm
				:key="companiesIdentityDetailsReloadKey"
				customerType="potentialCustomer"
			/>
		</div>
	</div>
</template>

<script>
import CustomerDetailsForm from '@/_srcv2/views/pages/admin-reports/customer-list/CustomerDetailsForm.vue'
import useCustomerDetailsForm from '@/_srcv2/views/pages/admin-reports/customer-list/useCustomerDetailsForm.js'

export default {
	name: 'PotentialCustomerList',
	components: { CustomerDetailsForm },
	setup() {
		const { companiesIdentityDetailsReloadKey } = useCustomerDetailsForm()
		return {
			companiesIdentityDetailsReloadKey,
		}
	},
}
</script>

<style scoped></style>
